import React, { useState } from 'react';
import { Menu, Row, Col, Button, Form, Input, Select } from 'antd';

const FeatureComponents = (props: any) =>{

    return <>
       <div id="reviewMain" >
            <Row className="container" justify="space-around">
              <Col sm={24} md={24} lg={24} xl={{span:24 , offset: 2}}>
                <div className="titleTextTop">
                  <h2>Sự thành công của khách hàng là động lực của chúng tôi</h2>
                </div>
              </Col>
              <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>15,528</h1>
                        <h2>Cửa hàng</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                  <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>652,135</h1>
                        <h2>Đơn hàng</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={6} lg={6} xl={6}>
              <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>68%</h1>
                        <h2>Lợi nhuận tăng</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={6} lg={6} xl={6}>
              <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>80%</h1>
                        <h2>KH quay lại</h2>
                      </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
    </>
}

export default FeatureComponents