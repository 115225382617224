import Layout from "themes/layouts/Home";
import FeatureComponents from "themes/components/General/FeatureComponents";
import OverViewCustomer from "themes/components/General/OverViewCustomer";
import { Button, Row, Col, Typography } from "antd";
import useBaseHooks from "themes/hooks/BaseHooks";

const Index = () => {
  const { redirect } = useBaseHooks();
  return (<Layout title="Trang chủ" >
      <div id="headerMain">
        <div className="container">
          <Row>
            <Col lg={24} xl={24}>
              <div className="TextContent">
                <h1 style={{ textAlign: "center" }}>
                FIUP - Tiên phong trong việc xây dựng mô hình cung cấp dịch vụ cao cấp kiểu mới 4.0
                </h1>
                <p>
                  Mang lại trải nghiệm tuyệt vời cho khách hàng nhờ công nghệ AI, IoT, dữ liệu lớn đầu tiên tại Việt Nam.
                </p>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
            <a href="https://shop.fiup.vn/admin/login">
                <Button className="btnLogin buttonHead">Đăng nhập</Button>
              </a>
            </Col>
            <Col>
            <Button
                className="BtnStartTest buttonHead"
                onClick={() => redirect("frontend.landing.trial")}
              >
                Dùng thử
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="bannerImageHeadMqn">
                <img src="/static/img/allDevice.png" alt="" />
              </div>
            </Col>
            <Col span={24}>
              <div className="imgHeaderMainMobi">
                <img
                  src="/static/img/5cb8f15180303e5665f0ce5f_5a2210c6803c400001732425_Group 6-p-800.png"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div id="bannerBodyMain">
        <div id="contentInfoBannerMqn" className="container">
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div className="contentLeft">
                <h3>Trải nghiệm 4.0</h3>
                <h1>Tăng trải nghiệm khách hàng tại cửa hàng nhờ áp dụng công nghệ AI</h1>
                <div className="lineheight"></div>
                <p>
                Hệ thống camera AI nhận diện khách hàng, tự động đưa ra lời chào bằng hệ thống loa hoặc thiết bị hiển thị. Đồng thời giúp nhân viên quản lý biết chính xác thông tin khách hàng, lịch sử phục vụ mà không cần hỏi khách hàng bất cứ thông tin gì. Sự cá nhân hóa tới từng khách hàng mang lại trải nghiệm bất ngờ, tuyệt vời.
                </p>
                <button>xem thêm</button>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="contentRight">
                <img
                  src="/static/img/caseone-reports-white@2x.png"
                  alt=""
                  width="100%"
                />
              </div>
            </Col>
          </Row>
          <div className="linehigtGrey"></div>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div className="contentRight">
                <img src="/static/img/caseone-timing-white@2x.png" alt="" width="80%" />
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="contentLeft">
                <h3>Lợi nhuận</h3>
                <h1>Tăng lợi nhuận nhờ hệ thống chăm sóc khách hàng, chốt sale được cá nhân hóa tối đa sử dụng công nghệ 4.0 và tiết kiệm chi phí lớn nhất.</h1>
                <div className="lineheight"></div>
                <p>Hệ thống nhắn tin tự động, thông minh, sử dụng AI nhắc lịch sử dụng dịch vụ mang lại cho khách hàng sự quan tâm, chăm sóc tốt nhất. Từ đó làm tăng tỷ lệ chuyển đổi khách hàng mới, gia tăng độ thân thiết của khách hàng hiện có, giúp cho doanh thu trên mỗi khách hàng tăng lên. Tiết kiệm chi phí sale, marketing nhờ hệ thống tương tác khách hàng liên tục giá rẻ.
                </p>
                <button>xem thêm</button>
              </div>
            </Col>
          </Row>
          <div className="linehigtGrey"></div>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div className="contentLeft">
                <h3>Mô hình dịch vụ 4.0</h3>
                <h1>Xây dựng mô hình cung cấp dịch vụ cao cấp 4.0 kết hợp giữa trải nghiệm tại điểm bán và chăm sóc sau bán hàng đầu tiên tại Việt Nam.</h1>
                <div className="lineheight"></div>

                <p>
                Nhân viên tương tác tức thời với hệ thống mọi lúc mọi nơi thông qua điện thoại, tạo nên mô hình cửa hàng hiện đại 4.0. Khách hàng được trải nghiệm mô hình dịch vụ VIP từ lúc bắt đầu tìm hiểu dịch vụ cho tới khi trở thành khách hàng thân thiết.
                </p>
                <button>xem thêm</button>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="contentRight">
                <img
                  src="/static/img/caseone-invoicing-white@2x.png"
                  alt=""
                  width="100%"
                />
              </div>
            </Col>
          </Row>
        </div>

        {/* Tồng quan đánh giá khách hàng */}
        <OverViewCustomer />
        {/* End tổng quan đánh giá khách hàng */}

        {/* Tính năng */}
        <FeatureComponents />
        {/* End tính năng */}

        <div id="aboutMeMain">
          <div className="container">
            <Row>
              <Col sm={24} md={12} lg={12} xl={12}>
                <div className="itemContentLeft">
                  <h1>Mô hình dịch vụ 4.0</h1>
                  <h2>Trải nghiệm mô hình cửa hàng dịch vụ 4.0 đầu tiên tại Việt Nam</h2>
                  <p>
                  Hãy biến của hàng của bạn thành cửa hàng kiểu mẫu sử dụng công nghệ 4.0 ngay hôm nay bằng cách liên với chúng tôi.{" "}
                  </p>
                  <button>LIÊN HỆ</button>
                </div>
              </Col>
              <Col sm={{span:24}} md={12} lg={12} xl={12}>
                <div className="videoYoutube">
                  <iframe
                    width="100%"
                    height="373"
                    src="https://www.youtube.com/embed/oi9PDK8-m6Y"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div id="priceTablePro" className="container">
          <Row gutter={24}>
            <Col xs={24} md={24} lg={24} xl={24}>
              <div className="titleMainPrice">
                <h1>bảng giá</h1>
                <h2>Sự đầu tư thông minh, mang lại doanh thu gấp nhiều lần.</h2>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} xl={8}>
              <div className="itemTablePriceProMqn">
                <div className="titleTypePack">
                  <h1>Gói cơ bản</h1>
                  <h2>6.900.000 VNĐ / Năm</h2>
                </div>
                <div className="contentItempack">
                  <p>
                    <b>Hạn sử dụng Zalo 100 lần/tháng</b>
                  </p>
                  <p>Tạo đơn hàng 700 đơn/tháng</p>
                  <p>Quản lý và tìm kiếm khách hàng</p>
                  <p>Lấy dữ liệu khách hàng từ Zalo ( Theo hạn mức Zalo )</p>
                  <p>Gửi tin cho khách qua Zalo ( Theo hạn mức zalo )</p>
                  <p>Gợi ý KH cần chăm sóc</p>
                  <p>Quản lý lịch hẹn của khách</p>
                  <p>Tài khoản nhân viên</p>
                  <p>Phân quyền tài khoản nhân viên</p>
                  <p>Quản lý sản phẩm</p>
                  <p>Chia sản phẩm theo danh mục</p>
                  <p>Quản lý kho</p>
                  <p>Cảnh báo tự động khi hết hàng ( Theo hạn mức zalo)</p>
                  <p>Tính doanh thu cửa hàng</p>
                  <p>Tính lương nhân viên</p>
                  <Button onClick={() => redirect("frontend.landing.price")}>Chi tiết</Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} xl={8}>
              <div className="itemTablePriceProMqn activeTablePrice">
                <div className="titleTypePack">
                  <Col lg={24} xl={24}>
                    <img src="/static/img/star.svg" alt="" />
                  </Col>
                  <h1>Gói chuyên nghiệp</h1>
                  <h2>8.900.000 / Năm</h2>
                </div>
                <div className="contentItempack">
                  <p>
                    <b>Hạn sử dụng Zalo 900 lần/tháng</b>
                  </p>
                  <p>Tạo đơn hàng 1200 đơn/tháng</p>
                  <p>Quản lý và tìm kiếm khách hàng</p>
                  <p>Lấy dữ liệu khách hàng từ Zalo ( Theo hạn mức Zalo )</p>
                  <p>Gửi tin cho khách qua Zalo ( Theo hạn mức zalo )</p>
                  <p>Gửi tin cho khách hàng qua SMS</p>
                  <p>Gửi tin cho khách hàng qua FB 9</p>
                  <p>Gợi ý KH cần chăm sóc</p>
                  <p>Quản lý lịch hẹn của khách</p>
                  <p>Tài khoản nhân viên</p>
                  <p>Phân quyền tài khoản nhân viên</p>
                  <p>Quản lý sản phẩm</p>
                  <p>Chia sản phẩm theo danh mục</p>
                  <p>Quản lý kho</p>
                  <p>Cảnh báo tự động khi hết hàng ( Theo hạn mức zalo)</p>
                  <p>Tính doanh thu cửa hàng</p>
                  <p>Tính lương nhân viên</p>
                  <p>Xem báo cáo theo chuỗi hệ thống </p>
                  <p>Tích hợp máy in </p>
                  <p>Tích hợp camera AI </p>
                  <Button onClick={() => redirect("frontend.landing.price")}>Chi tiết</Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} xl={8}>
              <div className="itemTablePriceProMqn">
                <div className="titleTypePack">
                  <h1>Không giới hạn</h1>
                  <h2>9.900.000 / Năm</h2>
                </div>
                <div className="contentItempack">
                  <p>
                    <b>Hạn sử dụng Zalo không giới hạn</b>
                  </p>
                  <p>Quản lý và tìm kiếm khách hàng</p>
                  <p>Lấy dữ liệu khách hàng từ Zalo ( Theo hạn mức Zalo )</p>
                  <p>Gửi tin cho khách qua Zalo ( Theo hạn mức zalo )</p>
                  <p>Gửi tin cho khách hàng qua SMS</p>
                  <p>Gửi tin cho khách hàng qua FB 9</p>
                  <p>Gợi ý KH cần chăm sóc</p>
                  <p>Quản lý lịch hẹn của khách</p>
                  <p>Tài khoản nhân viên</p>
                  <p>Phân quyền tài khoản nhân viên</p>
                  <p>Quản lý sản phẩm</p>
                  <p>Chia sản phẩm theo danh mục</p>
                  <p>Quản lý kho</p>
                  <p>Cảnh báo tự động khi hết hàng ( Theo hạn mức zalo)</p>
                  <p>Tính doanh thu cửa hàng</p>
                  <p>Tính lương nhân viên</p>
                  <p>Xem báo cáo theo chuỗi hệ thống </p>
                  <p>Tích hợp máy in </p>
                  <p>Tích hợp camera AI </p>
                  <p>Điều kiện mua máy in</p>
                  <Button onClick={() => redirect("frontend.landing.price")}>Chi tiết</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
