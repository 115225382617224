import React, { useState } from 'react';
import { Menu, Row, Col, Button, Form, Input, Select } from 'antd';

const FeatureComponents = (props: any) =>{

    return <>
        <div id="feature" className="container">
            <Row>
              <Col md={24} lg={24} xl={24}>
                <div className="titleFeature">
                  <h1>tính năng của salon</h1>
                  <h2>phần mềm quản lý kinh doanh thế hệ mới</h2>
                </div>
              </Col>
            </Row>
            
            <div className="contentFeature">
                <Row>
                  <Col md={24} lg={12} xl={12}>
                    <div className="contentItemFeature">
                        <img src="static/img/download (5).svg" alt=""/>
                        <div className="itemContentFeature">
                              <h1>Tìm kiếm và kiểm tra lịch sử khách hàng</h1>
                              <p>FIUP có thể tích hợp camera AI nhận diện khách hàng khi vừa bước vào cửa. Dễ dàng tra cứu lịch sử thông tin và chào tên khách mà không cần hỏi bất cứ thông tin gì</p>
                          </div>
                      </div>
                  </Col>
                  <Col md={24} lg={12}xl={12}>
                    <div className="contentItemFeature">
                        <img src="static/img/download (6).svg" alt=""/>
                        <div className="itemContentFeature">
                              <h1>Tính doanh thu cửa hàng tự động</h1>
                              <p>Tự động tính doanh thu, số lượng đơn hàng, tỷ lệ khách hàng theo từng ngày, từng tháng. Giúp bạn dễ dàng nắm bắt tình hình cửa hàng và so sánh giữa các ngày dù ở bất cứ nơi đâu.</p>
                          </div>
                      </div>
                  </Col>
                  <Col md={24} lg={12} xl={12}>
                    <div className="contentItemFeature">
                        <img src="static/img/download (7).svg" alt=""/>
                        <div className="itemContentFeature">
                              <h1>Tính lương nhân viên chính xác</h1>
                              <p>Giải quyết vấn đề nhức nhối trong tính lương nhân viên bằng cách tính lương tự động 100%, linh hoạt với số ngày công, thưởng phát của từng nhân viên. Hệ thống cũng cung cấp báo cáo lương chi tiết của nhân viên theo từng ngày và từng dịch vụ giúp bạn dễ dàng đối soát với nhân viên vào cuối tháng.</p>
                          </div>
                      </div>
                  </Col>
                  <Col md={24} lg={12} xl={12}>
                    <div className="contentItemFeature">
                        <img src="static/img/download (8).svg" alt=""/>
                        <div className="itemContentFeature">
                              <h1>Chăm sóc khách hàng chu đáo</h1>
                              <p>Chức năng nhắc khách khi đến lịch cần quay lại cửa hàng để sử dụng dịch vụ định kỳ bằng tin nhắn Zalo, Facebook giúp gia tăng mối quan hệ giữa cửa hàng và khách mang lại cho khách hàng cảm giác được chăm sóc chu đáo và chuyên nghiệp. Cũng nhờ vậy bạn sẽ tối ưu và tăng doanh số cửa hàng.</p>
                          </div>
                      </div>
                  </Col>
                  <Col md={24} lg={12} xl={12}>
                    <div className="contentItemFeature">
                        <img src="static/img/download (9).svg" alt=""/>
                        <div className="itemContentFeature">
                              <h1>Tính doanh thu cửa hàng tự động</h1>
                              <p>Tự động tính doanh thu, số lượng đơn hàng, tỷ lệ khách hàng theo từng ngày, từng tháng. Giúp bạn dễ dàng nắm bắt tình hình cửa hàng và so sánh giữa các ngày dù ở bất cứ nơi đâu.</p>
                          </div>
                      </div>
                  </Col>
                  <Col md={24} lg={12} xl={12}>
                    <div className="contentItemFeature">
                        <img src="static/img/download (10).svg" alt=""/>
                        <div className="itemContentFeature">
                              <h1>Quản lý kho đơn giản</h1>
                              <p>Chức năng nhập xuất kho được tối giản hóa để dễ dàng sử dụng chỉ với các thao tác đơn giản. Hệ thống cũng sẽ tự động gửi tin nhắn nhắc nhở bạn mỗi khi sản phẩm trong kho gần hết.</p>
                          </div>
                      </div>
                  </Col>
                </Row>
              </div>
          </div>
    </>
}

export default FeatureComponents